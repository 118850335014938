import Timeline, { TimelineItem } from '@/components/timeline';
import { CommerceRead_ScheduledPaymentsQuery } from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { CalendarMonthRounded, CheckCircle } from '@mui/icons-material';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { Fragment } from 'react';

export default function PaymentSchedulePreview( { commerce }: {
	commerce: CommerceRead_ScheduledPaymentsQuery['commerceRead']
} ) {
	const formik = useFormikContext<any>();
	const formikPayments = formik.values.payments || [];
	console.log( 'formikPayments', formikPayments );
	// combine all payments from commerce.scheduledPayments and formikPayments and sort by due Date for each
	const sortedPayments = [
		// First get all PAID payments and sort them by updatedAt date
		...( commerce.scheduledPayments.filter( ( payment ) => payment.status === 'PAID' ) || [] )
			.sort( ( a, b ) => new Date( a.updatedAt ).getTime() - new Date( b.updatedAt ).getTime() ),
		
		// Then get all SCHEDULED payments (from formikPayments) and sort them by dueDate
		...( formikPayments || [] )
			.sort( ( a, b ) => new Date( a.dueDate ).getTime() - new Date( b.dueDate ).getTime() ),
	];
	
	console.log( 'sortedPayments', sortedPayments );
	return (
		<Paper sx={{ p: 2, mt: 2, bgcolor: 'background.default' }}
			elevation={0}>
			<Stack spacing={2}>
				<Typography variant='h6'>
					Payment Schedule
				</Typography>
				
				{sortedPayments.length === 0 ? (
					<Box sx={{ p: 2, textAlign: 'center' }}>
						<Typography color='text.secondary'>
							No payments scheduled yet
						</Typography>
					</Box>
				) : (
					<Fragment>
						<Timeline>
							{sortedPayments.map( ( payment, index ) => (
								<TimelineItem
									key={payment.id}
									left={
										<Box sx={{}}>
											<Typography
												sx={{
													fontWeight: 500,
													whiteSpace: 'nowrap',
													color     : 'text.primary',
												}}>
												{safeFormatInTimeZone( payment.status === 'SCHEDULED'
													? payment.dueDate
													: payment.updatedAt, 'MMM d, yyyy' )}
											</Typography>
										</Box>
									}
									right={
										<Box>
											<Typography
												sx={{
													fontWeight: 600,
													color     : 'text.primary',
												}}>
												{currencyFormat( payment.amount )}
											</Typography>
											<Typography
												sx={{
													color: 'text.secondary',
												}}>
												{payment.reason || `Payment ${index + 1} of ${sortedPayments.length}`}
											</Typography>
										</Box>
									}
									timelineDot={
										<Box sx={{
											width       : 25,
											height      : 25,
											borderRadius: '50%',
											bgcolor     : 'background.default',
										}}>
											<Box
												sx={{
													width         : 25,
													height        : 25,
													borderRadius  : '50%',
													bgcolor       : payment.status === 'PAID' ? 'alpha.success' : 'alpha.warning',
													border        : 1,
													borderColor   : payment.status === 'PAID' ? 'success.main' : 'warning.main',
													display       : 'flex',
													alignItems    : 'center',
													justifyContent: 'center',
													color         : 'white',
												}}>
												{payment.status === 'PAID'
													? <CheckCircle sx={{ fontSize: 14, color: 'success.main' }}/>
													: <CalendarMonthRounded sx={{ fontSize: 14, color: 'warning.main' }}/>
												}
											</Box>
										</Box>
									}
								/>
							) )}
						</Timeline>
					</Fragment>
				)}
			</Stack>
		</Paper>
	);
}
