import { Maybe } from '@/generated/graphql';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { isEstimateType, isInvoiceType } from '@/helpers/useIsEstimateType';
import { calculations } from '@/pages/dashboard/commerce/components/calculations';
import { CommerceStatus } from '@/pages/dashboard/commerce/components/statuses';
import { invoiceLineItemsSelection } from '@/pages/p/commerce/invoiceEstimate/clientView/invoiceSelection';
import { CARD_FEE_LABEL } from '@/pages/p/commerce/invoiceEstimate/lineItems';
import { Order, Price } from '@/types/schema';
import {
	Box,
	Chip,
	Divider,
	ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { isEmpty, partition, round, sortBy, startCase, toLower } from 'lodash-es';
import { Fragment } from 'react';
import currencyFormat from '../helpers/currencyFormat';
import QRScanBox from './qrScanBox';

export function DisplayFeesAndDiscounts( { fees, subTotal, feeValue }: {
	fees: Price[],
	subTotal: number,
	feeValue?: number
} ) {
	return (
		<Fragment>{fees.map( ( fee, index ) => (
			<TableRow key={index}>
				<TableCell>
					<Typography px={1}>
						{fee.name} {fee.isPercent ? ` (${feeValue || fee.value}%)` : ''}
					</Typography>
				</TableCell>
				<TableCell align='right'>
					<Typography px={1}>
						{fee.isPercent
							? currencyFormat( subTotal * fee.value / 100 )
							: currencyFormat( fee.value )}
					</Typography>
				</TableCell>
			</TableRow>
		) )}
		</Fragment>
	);
}

export default function OrderProductsAndTotal( { data, timezone }: { data: Order, timezone?: string } ) {
	const { nonTaxPercentLineItemsTaxesData, orderTaxCal, orderDiscounts, multiTaxesData } = calculations( data );
	const [ cardFees, otherPrices ] = partition( data.prices, ( p ) => p?.name === CARD_FEE_LABEL );
	const sortedDiscountsAndFees = [ ...sortBy( !isEmpty( otherPrices )
		? [ ...otherPrices ]
		: [], [ ( o ) => o?.value ] ) ];
	const [ discounts, fees ] = partition( sortedDiscountsAndFees
		.filter( ( price ) => !price?.metadata?.cloverTaxPercent ), ( p ) => p && p.value < 0 );
	
	const companyCardFeeValue = data.company?.metadata?.cardFee;
	const selections = data.company.metadata?.selectionsMenu || [];
	const serviceCompany = toLower( data.company?.industry || '' ) === 'services' ? 'Service' : 'Product';
	
	const { showName, showPrice, showTotal, showUnit, showQuantity } = invoiceLineItemsSelection( selections );
	
	const viewLink = `${process.env.NEXT_PUBLIC_SITE_URL}/p/${data.id}/${isEstimateType( data.type )
		? 'estimate'
		: isInvoiceType( data.type ) ? 'invoice' : `${toLower( data.type )}`}`;
	
	return (
		<Fragment>
			<Table sx={{ '.MuiTableCell-root': { border: '0 !important', py: .5, px: 0 } }}>
				<TableBody>
					<TableRow>
						<TableCell width='70%' valign='top' sx={{ display: 'block' }}>
							<Box sx={{ fontSize: 30, fontWeight: 'bold' }}>
								{currencyFormat( data.grandTotal || 0 )}
							</Box>
							{data.metadata?.enableCashDiscount && data.company.metadata?.cashDiscount > 0 && (
								<Chip
									sx={{ alignItems: 'center' }}
									variant='alpha'
									color='success'
									label='Cash Discount'
								/>
							)}
						</TableCell>
						<TableCell width='30%' align='right'>
							{data?.id && (
								<QRScanBox
									clickToView
									boxProps={{ sx: { cursor: 'pointer' } }}
									data={viewLink}
								/>
							)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Divider sx={{ my: 2 }}/>
			<Table sx={{ '.MuiTableCell-root': { border: '0 !important', py: .5, px: 0 } }}>
				<TableBody>
					{data.dueDate && ( !isEstimateType( data.type ) ? (
						<TableRow>
							<TableCell sx={{ width: 120 }} align='left'>
								Due on
							</TableCell>
							<TableCell align='right'>
								{safeFormatInTimeZone( data.dueDate, 'PP', data.companyLocation?.timezone || timezone )}
							</TableCell>
						</TableRow>
					) : (
						<TableRow>
							<TableCell sx={{ width: 120 }} align='left'>
								Expires on
							</TableCell>
							<TableCell align='right'>
								{safeFormatInTimeZone( data.dueDate, 'PP', data.companyLocation?.timezone || timezone )}
							</TableCell>
						</TableRow>
					) )}
					<TableRow>
						<TableCell sx={{ width: 120 }} align='left'>
							Service date:
						</TableCell>
						<TableCell align='right'>
							{safeFormatInTimeZone( data.serviceDate, 'PP hh:mm', data.companyLocation?.timezone || timezone )}
						</TableCell>
					</TableRow>
					{data?.client && (
						<TableRow>
							<TableCell sx={{ width: 120 }} align='left'>
								Company
							</TableCell>
							<TableCell align='right'>
								{data?.client?.name || data?.client?.contact}
							</TableCell>
						</TableRow>
					)}
					<TableRow>
						<TableCell sx={{ width: 120 }} align='left'>
							{startCase( toLower( data.type ) )}#
						</TableCell>
						<TableCell align='right'>
							{data.metadata?.customNumber || data.number}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell sx={{ width: 120 }} align='left'>
							From
						</TableCell>
						<TableCell align='right'>
							{data.company?.name || data.company?.contact}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell sx={{ width: 120 }} align='left'>
							Status
						</TableCell>
						<TableCell align='right'>
							<Chip
								sx={{ alignItems: 'center' }}
								variant='alpha'
								color={!data.standingActive
									? CommerceStatus[ data.type ][ data?.status ]?.color
									: 'success'} // TODO: Change Chip to <CommerceStatusChip clientView/> to prevent doing the standingActive check
								label={CommerceStatus[ data.type ][ data.status ]?.clientView}
							/>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Divider sx={{ my: 2 }}/>
			<Typography sx={{ mb: 1, color: 'text.secondary', fontWeight: 500 }}>
				{`${serviceCompany}s` || 'Products'}
			</Typography>
			<TableContainer
				component={Paper}
				variant='bordered'
				sx={{ td: { borderColor: 'divider', px: '10px !important' }, mb: 1 }}>
				<Table>
					<TableHead sx={{ bgcolor: 'divider', p: 1 }}>
						<TableRow>
							<TableCell align='left' sx={{ borderBottom: 0, p: '10px !important' }}>
								<Typography p={1} variant='bold'>
									{serviceCompany || 'Product'}
								</Typography>
							</TableCell>
							<TableCell align='right' sx={{ borderBottom: 0, p: '10px !important' }}>
								<Typography p={1} variant='bold'>
									Price
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.lineItems?.slice( 0, 10 ).map( ( item, index ) => (
							<TableRow key={index}>
								<TableCell align='left' sx={{ width: '80%', py: 0.2 }}>
									<ListItemText
										primary={showName ? item.name : ''}
										primaryTypographyProps={{ sx: { whiteSpace: 'normal', wordBreak: 'break-word' } }}
										secondaryTypographyProps={{ component: 'div' }}
										secondary={(
											<Typography component='span' color='text.secondary'>
												{showQuantity ? item.quantity : ''} {showPrice ? showQuantity
													? ` x ${currencyFormat( item.price )}`
													: currencyFormat( item.price ) : ''} {showUnit ? showPrice
													? `| ${item.unit}`
													: item.unit : ''}
											</Typography>
										)}
										sx={{ px: 1 }}
									/>
								</TableCell>
								{showTotal && (
									<TableCell align='right' sx={{ width: '20%' }}>
										<Typography px={1}>
											{currencyFormat( item.price * item.quantity )}
										</Typography>
									</TableCell>
								)}
							</TableRow>
						) )}
					</TableBody>
				</Table>
				{data.lineItems?.length > 10 && (
					<Table sx={{ '.MuiTableCell-root': { border: 0, py: .5, px: 0 } }}>
						<TableBody>
							<TableRow>
								<TableCell>
									<Box sx={{ textAlign: 'center', pb: 1 }}>
										<Typography
											component='a'
											target='_blank'
											sx={{ textDecoration: 'none', color: 'primary.main' }}
											href={`${process.env.NEXT_PUBLIC_SITE_URL}/p/${data.id}/${isEstimateType( data.type )
												? 'estimate'
												: isInvoiceType( data.type ) ? 'invoice' : `${toLower( data.type )}`}`}>
											View All..
										</Typography>
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}
				<Table sx={{ bgcolor: 'divider' }}>
					<TableBody sx={{ '&:last-child td, &:last-child th': { border: '0px !important' }, 'td': { py: 1 } }}>
						<TableRow key='sub'>
							<TableCell>
								<Typography px={1}>
									SubTotal
								</Typography>
							</TableCell>
							<TableCell align='right' sx={{ px: .5 }}>
								<Typography px={1}>
									{currencyFormat( data.subTotal )}
								</Typography>
							</TableCell>
						</TableRow>
						{data.subTotal > 0 && !isEmpty( multiTaxesData ) && multiTaxesData.map( ( taxData, index ) => (
							<TableRow key={index}>
								<TableCell>
									<Typography px={1}>
										{taxData?.[ 0 ]} {taxData?.[ 3 ] ? ` (${taxData?.[ 1 ]}%)` : ''}
									</Typography>
								</TableCell>
								<TableCell align='right'>
									<Typography px={1}>
										{currencyFormat( taxData?.[ 2 ] ?? 0 )}
									</Typography>
								</TableCell>
							</TableRow>
						) ) || []}
						{!isEmpty( nonTaxPercentLineItemsTaxesData )
							&& nonTaxPercentLineItemsTaxesData.map( ( taxData, index ) => (
								<TableRow key={index}>
									<TableCell>
										<Typography px={1}>
											Tax {` (${taxData?.[ 0 ]}%)`}
										</Typography>
									</TableCell>
									<TableCell align='right'>
										<Typography px={1}>
											{currencyFormat( taxData?.[ 1 ] )}
										</Typography>
									</TableCell>
								</TableRow>
							) )}
						{orderTaxCal <= 0 || data.taxPercent <= 0 || data.taxTotal <= 0 && isEmpty( orderDiscounts )
							? undefined
							: (
								<TableRow key='taxTt'>
									<TableCell>
										<Typography px={1}>
											Tax {` (${data.taxPercent}%)`}
										</Typography>
									</TableCell>
									<TableCell align='right'>
										<Typography px={1}>
											{currencyFormat( orderTaxCal )}
										</Typography>
									</TableCell>
								</TableRow>
							)}
						{!isEmpty( discounts ) && data.subTotal
							&& <DisplayFeesAndDiscounts fees={discounts} subTotal={data.subTotal}/> || []}
						{!isEmpty( fees ) && data.subTotal
							&& <DisplayFeesAndDiscounts fees={fees} subTotal={data.subTotal}/> || []}
						{!isEmpty( cardFees ) && data.subTotal
							&& (
								<DisplayFeesAndDiscounts
									fees={cardFees}
									subTotal={data.subTotal}
									feeValue={companyCardFeeValue}
								/>
							) || []}
						<TableRow key='grand'>
							<TableCell>
								<Typography px={1}>
									Total
								</Typography>
							</TableCell>
							<TableCell align='right'>
								<Typography px={1}>
									{currencyFormat( data.grandTotal )}
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			{data?.scheduledPayments?.length > 0 && (
				<Typography sx={{ my: 1, color: 'text.secondary', fontWeight: 500 }}>
					Scheduled payments
				</Typography>
			)}
			{data?.scheduledPayments?.length > 0 && (
				<TableContainer
					component={Paper}
					variant='bordered'
					sx={{ td: { borderColor: 'divider', px: '10px !important' }, mb: 2 }}>
					<Table sx={{ '.MuiTableCell-root': { p: '10px !important' } }}>
						<TableHead sx={{ bgcolor: 'divider', p: 1 }}>
							<TableRow>
								<TableCell>
									Date
								</TableCell>
								<TableCell>
									Amount
								</TableCell>
								<TableCell align='right'>
									Status
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.scheduledPayments?.map( ( payment, index ) => (
								<TableRow key={index}>
									<TableCell align='left'>
										{format( payment.dueDate, 'PP' )}
									</TableCell>
									<TableCell>
										{currencyFormat( round( data.grandTotal * ( payment?.percent || 0 ) / 100, 2 ) )}
									</TableCell>
									<TableCell align='right' sx={{ color: payment.status === 'PAID' ? 'success.main' : 'warning.main' }}>
										{payment.status}
									</TableCell>
								</TableRow>
							) )}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Fragment>
	);
}
