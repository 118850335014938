import { mutateGraphQL } from '@/data/apollo';
import { PaymentWrite } from '@/data/commerce/payment.graphql';
import { Order } from '@/generated/graphql';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import CommerceItemsUpdating from '@/pages/dashboard/commerce/invoices/commerceItemsUpdating';
import { useModal } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { MutationPaymentWriteArgs } from '@/types/schema';
import { Checkbox, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const methods = ( t ) => [
	{ label: t( 'common:mark-as-paid-by-card' ), value: 'CARD' },
];

export function MarkAsPaidInvoice( { invoice }: { invoice: Order } ) {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const [ selectedMethod, setSelectedMethod ] = useState( '' );
	
	const markAsPaid = async ( invoice: Order, method: string ) => {
		const { paymentWrite } = await mutateGraphQL<MutationPaymentWriteArgs>( {
			mutation : PaymentWrite,
			variables: {
				id    : null,
				method: 'Marked Paid',
				input : {
					gateway: invoice.gateway?.id || null,
					status : 'PAID',
					type   : method || 'Manual',
					amount : invoice.grandTotal - invoice.paidTotal,
					tip    : 0,
					client : invoice.client?.id || null,
					order  : invoice.id || null,
					note   : 'Marked as paid',
				},
			},
		} );
		return paymentWrite;
	};
	return (
		<ResponsiveModalContainer
			title={`${t( 'common:mark-paid' )} & ${t( 'commerce:update-stock' )}`}
			secondaryTitle={`${t( 'common:mark-paid-pre' )} ${invoice.externalId && invoice.gateway?.external === 'CLOVER'
				? t( 'common:mark-paid-post' )
				: ''}`}
			saveButtonText={`${t( 'common:mark-paid' )}`}
			actionItems={
				[
					{
						name   : `${t( 'common:mark-paid' )} & ${t( 'commerce:update-stock' )}`,
						onClick: async () => {
							const paymentWrite = await markAsPaid( invoice, selectedMethod );
							if ( paymentWrite ) {
								showModal( CommerceItemsUpdating, {
									maxWidth: 'lg',
									onClose : ( event, reason ) => stayOpen( event, reason ),
								}, { commerce: invoice } );
							}
						},
					},
				]
			}
			onSave={async () => await markAsPaid( invoice, selectedMethod )}>
			<Stack>
				{methods( t ).map( ( method ) => (
					<ListItemButton
						key={method.value}
						onClick={() => setSelectedMethod( ( prev ) => prev === method.value ? '' : method.value )}>
						<ListItemText>
							<Stack direction='row' alignItems='center' spacing={1}>
								<Checkbox checked={selectedMethod === method.value}/>
								<Typography>
									{method.label}
								</Typography>
							</Stack>
						</ListItemText>
					</ListItemButton>
				) )}
			</Stack>
		</ResponsiveModalContainer>
	
	);
}

export function MarkAsPaidOrder( { order }: { order: Order } ) {
	const { t } = useTranslation();
	const [ selectedMethod, setSelectedMethod ] = useState( '' );
	
	const markAsPaid = async ( order: Order, method: string ) => {
		const { paymentWrite } = await mutateGraphQL<MutationPaymentWriteArgs>( {
			mutation : PaymentWrite,
			variables: {
				id    : null,
				method: 'Marked as Paid',
				input : {
					gateway: order.gateway?.id || null,
					status : 'PAID',
					type   : method || 'Manual',
					amount : order.grandTotal,
					tip    : 0,
					client : order?.client?.id || null,
					order  : order?.id || null,
					note   : 'Marked as paid',
				},
			},
		} );
		return paymentWrite;
	};
	return (
		<ResponsiveModalContainer
			title={t( 'common:mark-paid' )}
			secondaryTitle={`${t( 'commerce:mark-order-as-paid' )} ${order.externalId && order.gateway?.external === 'CLOVER'
				? t( 'commerce:order-was-synced-to-clover' )
				: ''}`}
			saveButtonText={`${t( 'common:mark-paid' )}`}
			onSave={async () => await markAsPaid( order, selectedMethod )}>
			<Stack>
				{methods( t ).map( ( method ) => (
					<ListItemButton
						key={method.value}
						onClick={() => setSelectedMethod( ( prev ) => prev === method.value ? '' : method.value )}>
						<ListItemText>
							<Stack direction='row' alignItems='center' spacing={1}>
								<Checkbox checked={selectedMethod === method.value}/>
								<Typography>
									{method.label}
								</Typography>
							</Stack>
						</ListItemText>
					</ListItemButton>
				) )}
			</Stack>
		</ResponsiveModalContainer>
	
	);
}