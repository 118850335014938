import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormDatePicker from '@/components/form/fields/datePicker';
import FormTextField from '@/components/form/fields/textField';
import { CommerceRead_ScheduledPaymentsQuery } from '@/generated/graphql';
import getAllScheduledPaymentsWithProperAmounts from '@/modals/scheduledPayments/utils';
import { Box, Button, Collapse, ListItem, ListItemIcon, ListItemText, Paper, Radio, Tooltip } from '@mui/material';
import { addDays } from 'date-fns';
import { useFormikContext } from 'formik';
import { round } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export default function SplitEvenlyPayments( {
	commerce,
}: {
	commerce: CommerceRead_ScheduledPaymentsQuery['commerceRead'],
} ) {
	const formik = useFormikContext<any>();
	
	const formikPayments = formik.values.payments;
	const scheduleType = formik.values.schedulePaymentsData?.type;
	const remainingAfterAllPaid = commerce?.grandTotal - commerce.paidTotal;
	
	const disabled = scheduleType === 'CUSTOM' && formikPayments.length > 0;
	
	const handleGeneratePayments = async () => {
		if ( !commerce.grandTotal ) return;
		
		// Get the number of payments from form
		const numberOfPayments = parseInt( formik.values.schedulePaymentsData.splitEvenlyNumberOfPayments );
		if ( !numberOfPayments || numberOfPayments <= 0 ) return;
		
		// Calculate what percentage has already been paid - round to exactly 2 decimal places
		const paidPercentage = commerce.paidTotal > 0 ? round( commerce.paidTotal / commerce.grandTotal * 100, 2 ) : 0;
		// Calculate the remaining percentage to be allocated - round to exactly 2 decimal places
		const remainingPercentage = round( 100 - paidPercentage, 2 );
		
		// Track the total percentage allocated so far
		let totalPercentAllocated = 0;
			
		const newPaymentsArray = Array.from( { length: numberOfPayments }, ( _, index ) => {
			// Get interval and start date
			const interval = parseInt( formik.values.schedulePaymentsData.splitEvenlyInterval ) || 30;
			const startDate = formik.values.schedulePaymentsData.splitEvenlyStartDate || new Date();
			const dueDate = index === 0
				? new Date( startDate )
				: addDays( new Date( startDate ), index * interval );
			
			let percent = 0;
			
			// For all payments except the last one
			if ( index < numberOfPayments - 1 ) {
				// Regular payment - evenly divided percentage of the remaining amount
				percent = round( remainingPercentage / numberOfPayments, 2 );
				totalPercentAllocated += percent;
			} else {
				// Last payment - just take whatever is left to reach exactly 100%
				percent = round( remainingPercentage - totalPercentAllocated, 2 );
			}
			
			return {
				id    : uuidv4(),
				percent,
				status: 'SCHEDULED',
				dueDate,
				reason: '',
				staff : formik.values.staff,
			};
		} );
	
		// Create an array to hold our new payments
		const newPayments = getAllScheduledPaymentsWithProperAmounts( newPaymentsArray, commerce.grandTotal, Math.max( 0, remainingAfterAllPaid ) );
		
		// Update form values
		formik.setFieldValue( 'payments', newPayments );
		formik.setFieldValue( 'schedulePaymentsData', {
			...formik.values.schedulePaymentsData,
			type: 'SPLIT_EVENLY',
		} );
	};
	
	return (
		<Tooltip title={disabled
			? 'You have already scheduled payments, you must clear them before switching to custom.'
			: ''}>
			<Box>
				<Paper
					sx={{
						p      : 2,
						bgcolor: 'background.default',
						...disabled && {
							opacity      : 0.5,
							pointerEvents: 'none',
						},
					}}>
					<ListItem disablePadding>
						<ListItemIcon>
							<Radio
								checked={scheduleType === 'SPLIT_EVENLY'}
								onChange={() => {
									formik.setFieldValue( 'schedulePaymentsData', {
										type                       : 'SPLIT_EVENLY',
										splitEvenlyNumberOfPayments: null,
										splitEvenlyStartDate       : new Date(),
										splitEvenlyInterval        : 30,
									} );
								}}
							/>
						</ListItemIcon>
						<ListItemText
							primary='Payment Plan'
							primaryTypographyProps={{ variant: 'h5' }}
						/>
						{scheduleType === 'SPLIT_EVENLY' && formikPayments.length > 0 && (
							<Button
								variant='text'
								color='warning'
								onClick={() => {
									formik.setFieldValue( 'schedulePaymentsData', {
										splitEvenlyNumberOfPayments: null,
										type                       : 'SPLIT_EVENLY',
									} );
									formik.setFieldValue( 'payments', [] );
								}}>
								Clear
							</Button>
						)}
					</ListItem>
					<Collapse in={scheduleType === 'SPLIT_EVENLY'} sx={{ width: '100%' }}>
						<ListItem>
							<ListItemText
								primary='Number of Payments'
								secondary='How many equal payments to split the total into. (1 - 99)'
							/>
							<Box>
								<FormTextField
									type='number'
									name='schedulePaymentsData.splitEvenlyNumberOfPayments'
									sx={{ width: '100px' }}
									format={( value ) => {
										const parsedValue = +value || 1;
										return Math.min( Math.max( parsedValue, 1 ), 99 );
									}}
									onFocus={( event ) => event.target.select()}
								/>
							</Box>
						</ListItem>
						<ListItem>
							<ListItemText
								primary='First Payment Date'
								secondary='When should the first payment be charged.'
							/>
							<Box>
								<FormDatePicker
									disablePast
									name='schedulePaymentsData.splitEvenlyStartDate'
								/>
							</Box>
						</ListItem>
						<ListItem>
							<ListItemText
								primary='Payment Interval'
								secondary='Days between each payment. (1 - 90)'
							/>
							<Box>
								<FormTextField
									type='number'
									name='schedulePaymentsData.splitEvenlyInterval'
									format={( value ) => {
										const parsedValue = +value || 1;
										return Math.min( Math.max( parsedValue, 1 ), 90 );
									}}
									sx={{ width: '100px' }}
									onFocus={( event ) => event.target.select()}
								/>
							</Box>
						</ListItem>
						<ListItem>
							<ListItemText/>
							<AsyncLoadingButton
								fullWidth
								variant='contained'
								color='primary'
								sx={{ width: 'fit-content' }}
								disabled={!formik.values.schedulePaymentsData.splitEvenlyNumberOfPayments || !formik.values.schedulePaymentsData.splitEvenlyStartDate}
								onClick={handleGeneratePayments}>
								Schedule Payments
							</AsyncLoadingButton>
						
						</ListItem>
					</Collapse>
				</Paper>
			</Box>
		</Tooltip>
	);
}
