import { CommerceRead_ScheduledPaymentsQuery } from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { HelpCenterRounded as HelpCenterRoundedIcon } from '@mui/icons-material';
import { Divider, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { startCase, toLower } from 'lodash-es';

export default function TotalSection( {
	commerce,
}: {
	commerce: CommerceRead_ScheduledPaymentsQuery['commerceRead'],
} ) {
	const formik = useFormikContext<any>();
	
	const formikPayments = formik.values?.payments;
	// Total remaining after all paid schedule payments
	const remainingAfterAllPaid = commerce.grandTotal - commerce.paidTotal;
	
	console.log( 'remainingAfterAllPaid', remainingAfterAllPaid );
	
	// Total of all payments with status SCHEDULED
	const totalScheduledPayments = formikPayments
		.filter( ( payment ) => payment.status === 'SCHEDULED' )
		.reduce( ( total, payment ) => total + Number( payment.amount ), 0 );
	
	console.log( 'totalScheduledPayments', totalScheduledPayments );
	console.log( 'remaining', remainingAfterAllPaid - totalScheduledPayments );
	return (
		<Paper sx={{ p: 1, bgcolor: 'background.default' }}>
			<Stack spacing={1} sx={{ p: 1, height: '100%' }}>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Typography color='text.secondary'>
						Grand Total:
					</Typography>
					<Typography variant='h3'>
						{currencyFormat( commerce?.grandTotal )}
					</Typography>
				</Stack>
				<Divider/>
				{/* {!commerce?.number?.includes( 'Draft' ) && ( */}
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Typography color='text.secondary'>
						Number:
					</Typography>
					<Typography>
						{`(#${commerce.metadata?.customNumber || commerce.number})`}
					</Typography>
				</Stack>
				{/* )} */}
				<Divider/>
				{commerce?.dueDate && (
					<Stack direction='row' alignItems='center' justifyContent='space-between'>
						<Typography color='text.secondary'>
							{startCase( toLower( commerce.type ) )} Due Date:
						</Typography>
						<Typography>
							{safeFormatInTimeZone( commerce.dueDate, 'PP' )}
						</Typography>
					</Stack>
				)}
				<Divider/>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Stack direction='row' alignItems='center' spacing={1}>
						<Typography color='text.secondary'>
							Remaining After All Scheduled:
						</Typography>
						<Tooltip title='Amount still unscheduled after all planned payments. If negative, you have scheduled more than the invoice total.'>
							<HelpCenterRoundedIcon
								sx={{
									fontSize    : 16,
									borderRadius: 1,
									color       : 'text.secondary',
								}}
							/>
						</Tooltip>
					</Stack>
					<Typography fontWeight='500'>
						{currencyFormat( Math.max( 0, remainingAfterAllPaid - totalScheduledPayments ) )}
					</Typography>
				</Stack>
				<Divider/>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Stack direction='row' alignItems='center' spacing={1}>
						<Typography color='text.secondary'>
							Remaining After All Paid:
						</Typography>
						<Tooltip title='Amount still to be collected after considering only payments that have already been paid. This is the actual outstanding balance.'>
							<HelpCenterRoundedIcon
								sx={{
									fontSize    : 16,
									borderRadius: 1,
									color       : 'text.secondary',
								}}
							/>
						</Tooltip>
					</Stack>
					<Typography fontWeight='500'>
						{currencyFormat( commerce.grandTotal - commerce.paidTotal )}
					</Typography>
				</Stack>
			</Stack>
		</Paper>
	);
};
