import FormDatePicker from '@/components/form/fields/datePicker';
import FormTextField from '@/components/form/fields/textField';
import FormattedTextField from '@/components/formattedTextField';
import { CommerceRead_ScheduledPaymentsQuery } from '@/generated/graphql';
import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Button,
	Collapse,
	Divider,
	Grid,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Radio,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { addDays } from 'date-fns';
import { useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { round } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export default function CustomPayments( {
	commerce,
}: {
	commerce: CommerceRead_ScheduledPaymentsQuery['commerceRead'],
} ) {
	const { t } = useTranslation();
	const formik = useFormikContext<any>();
	
	const formikPayments = formik.values?.payments;
	const scheduleType = formik.values.schedulePaymentsData.type;
	const disabled = scheduleType === 'SPLIT_EVENLY' && formikPayments.length > 0;
	
	const handlePaymentChange = ( index: number, type: 'amount' | 'percent', value: number ) => {
		const payments = [ ...formikPayments ];
		
		// Ensure value is not negative
		const positiveValue = Math.abs( +value );
		
		// Calculate the remaining amount after all paid payments
		const remainingAfterAllPaid = commerce?.grandTotal - commerce.paidTotal;
		const grandTotal = commerce?.grandTotal || 0;
		
		// Calculate the total of other unpaid payments (excluding the current one)
		const otherUnpaidPaymentsTotal = payments
			.filter( ( p, i ) => i !== index )
			.reduce( ( total, p ) => total + Number( p.amount ), 0 );
		
		// Calculate the maximum amount this payment can be - cannot exceed the actual remaining amount
		const maxAmountForThisPayment = Math.min(
			remainingAfterAllPaid,
			remainingAfterAllPaid - otherUnpaidPaymentsTotal,
		);
		
		// Calculate the total percent of other unpaid payments (excluding the current one)
		// Calculate percentages based on grand total, not remaining
		const otherUnpaidPaymentsPercent = payments
			.filter( ( p, i ) => i !== index )
			.reduce( ( total, p ) => {
				// Recalculate percent based on grand total
				const percentOfGrandTotal = grandTotal > 0 ? Number( p.amount ) / grandTotal * 100 : 0;
				return total + percentOfGrandTotal;
			}, 0 );
		
		// Calculate the maximum percent this payment can be (based on grand total)
		// Ensure the percent can't make the payment exceed the remaining amount
		const maxPercentForThisPayment = grandTotal > 0
			? Math.min( 100 - otherUnpaidPaymentsPercent, maxAmountForThisPayment / grandTotal * 100 )
			: 0;
		
		let finalAmount: number;
		let finalPercent: number;
		
		if ( type === 'amount' ) {
			// Ensure the amount doesn't exceed the maximum
			finalAmount = positiveValue > maxAmountForThisPayment
				? maxAmountForThisPayment
				: positiveValue;
			
			// Calculate the corresponding percent of the grand total
			finalPercent = grandTotal > 0
				? finalAmount / grandTotal * 100
				: 0;
		} else { // type === 'percent'
			// Ensure the percent doesn't exceed the maximum
			finalPercent = positiveValue > maxPercentForThisPayment
				? maxPercentForThisPayment
				: positiveValue;
			
			// Calculate the corresponding amount based on the grand total
			finalAmount = grandTotal * ( finalPercent / 100 );
			
			// Ensure the amount doesn't exceed remaining after paid
			if ( finalAmount > remainingAfterAllPaid ) {
				finalAmount = remainingAfterAllPaid;
				finalPercent = grandTotal > 0 ? finalAmount / grandTotal * 100 : 0;
			}
		}
		
		// Update the payment
		payments[ index ] = {
			...payments[ index ],
			amount : finalAmount,
			percent: finalPercent,
		};
		
		formik.setFieldValue( 'payments', payments );
	};
	
	return (
		<Tooltip
			placement='bottom'
			title={disabled ? 'You have already scheduled payments, you must clear them before switching to custom.' : ''}>
			<Box>
				<Paper sx={{
					p      : 2,
					bgcolor: 'background.default',
					...disabled && {
						opacity      : 0.5,
						pointerEvents: 'none',
					},
				}}>
					<ListItem disablePadding>
						<ListItemIcon>
							<Radio
								checked={scheduleType === 'CUSTOM'}
								onChange={() => {
									formik.setFieldValue( 'schedulePaymentsData', {
										type                       : 'CUSTOM',
										splitEvenlyNumberOfPayments: null,
										splitEvenlyStartDate       : null,
										splitEvenlyInterval        : 30,
									} );
								}}
							/>
						</ListItemIcon>
						<ListItemText
							primary='Custom'
							primaryTypographyProps={{ variant: 'h5' }}
						/>
						{scheduleType === 'CUSTOM' && (
							<Stack spacing={1} direction='row' alignItems='center'>
								{formikPayments.length > 0 && (
									<Button
										variant='text'
										color='warning'
										onClick={() => {
											formik.setFieldValue( 'schedulePaymentsData', {
												splitEvenlyNumberOfPayments: null,
												type                       : 'CUSTOM',
											} );
											formik.setFieldValue( 'payments', formikPayments.filter( ( payment ) => payment.status !== 'SCHEDULED' ) );
										}}>
										Clear
									</Button>
								)}
								<Button
									size='small'
									variant='outlined'
									color='primary'
									onClick={() => {
										const payments = [ ...formikPayments ];
										payments.push( {
											id     : uuidv4(),
											amount : 0,
											percent: 0,
											status : 'SCHEDULED',
											dueDate: null,
											reason : '',
										} );
										formik.setFieldValue( 'payments', payments );
									}}>
									{t( 'common:add-payment' )}
								</Button>
							</Stack>
						)}
					</ListItem>
					<Collapse in={scheduleType === 'CUSTOM'} timeout={1000}>
						{formikPayments.length > 0 ? (
							<Box sx={{ mt: 2 }}>
								<Grid container spacing={2} sx={{ mb: 2, px: 2 }}>
									<Grid item xs={4}>
										<Typography variant='caption' color='text.secondary'>
											{t( 'common:date-payment' )}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='caption' color='text.secondary'>
											{t( 'common:amount' )}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant='caption' color='text.secondary'>
											{t( 'common:percent' )}
										</Typography>
									</Grid>
									<Grid item xs={2}></Grid>
								</Grid>
								
								<div>
									<ul style={{ margin: 0, padding: 0 }}>
										<AnimatePresence initial={false}>
											{formikPayments.map( ( payment, index ) => (
												<motion.li
													key={payment.id}
													initial={{ opacity: 0, height: 0 }}
													animate={{ opacity: 1, height: 'auto' }}
													exit={{ opacity: 0, height: 0 }}
													transition={{
														opacity: { duration: 0.15 },
														height : { duration: 0.15 },
													}}
													style={{
														overflow : 'hidden',
														listStyle: 'none',
													}}>
													<Box sx={{ mb: 3 }}>
														<Grid container spacing={2} alignItems='center' sx={{ px: 2 }}>
															<Grid item xs={4}>
																<FormDatePicker
																	name={`payments[${index}].dueDate`}
																	minDate={index === 0 || !formikPayments?.[ index - 1 ]?.dueDate
																		? new Date()
																		: addDays( formikPayments?.[ index - 1 ]?.dueDate, 1 )}
																/>
															</Grid>
															<Grid item xs={3}>
																<FormattedTextField
																	fullWidth
																	value={round( formikPayments[ index ].amount, 2 )}
																	type='number'
																	InputProps={{
																		startAdornment: <Typography color='text.secondary'
																			pr={.5}>$</Typography>,
																	}}
																	onFocus={( event ) => event.target.select()}
																	onChange={( e ) => handlePaymentChange( index, 'amount', +e.target.value )}
																/>
															</Grid>
															<Grid item xs={3}>
																<FormattedTextField
																	fullWidth
																	value={round( formikPayments[ index ].percent, 2 )}
																	type='number'
																	InputProps={{
																		startAdornment: <Typography color='text.secondary'
																			pr={.5}>%</Typography>,
																	}}
																	onFocus={( event ) => event.target.select()}
																	onChange={( e ) => handlePaymentChange( index, 'percent', +e.target.value )}
																/>
															</Grid>
															<Grid item xs={2} sx={{ textAlign: 'right' }}>
																<Tooltip title='Remove'>
																	<IconButton
																		size='small'
																		color='error'
																		onClick={() => {
																			// Filter out the payment with this ID
																			const payments = formikPayments.filter( ( p ) => p.id !== payment.id );
																			formik.setFieldValue( 'payments', payments );
																		}}>
																		<CloseIcon fontSize='small'/>
																	</IconButton>
																</Tooltip>
															</Grid>
														</Grid>
														
														<Box sx={{ px: 2, mt: 1 }}>
															<FormTextField
																fullWidth
																multiline
																rows={2}
																name={`payments[${index}].reason`}
																placeholder={t( 'common:reason-payment' )}
															/>
														</Box>
														
														{index < formikPayments.length - 1 && (
															<Divider sx={{ mt: 3 }}/>
														)}
													</Box>
												</motion.li>
											) )}
										</AnimatePresence>
									</ul>
								</div>
							</Box>
						) : (
							
							<Typography color='text.secondary' textAlign='center'>
								There are no scheduled payments, click on "Add A Payment" to add one.
							</Typography>
						)}
					</Collapse>
				</Paper>
			</Box>
		</Tooltip>
	);
};
