import { Box } from '@mui/material';
import { Children, cloneElement, isValidElement, ReactNode } from 'react';

type TimelineItemProps = {
	left?: ReactNode,
	right?: ReactNode,
	timelineDot?: ReactNode,
};

export function TimelineItem( {
	left,
	right,
	timelineDot,
}: TimelineItemProps ) {
	return (
		<Box
			sx={{
				'position'    : 'relative',
				'width'       : '100%',
				'marginBottom': 3,
				'&:last-child': {
					marginBottom: 0,
				},
			}}>
			{/* Timeline dot */}
			<Box
				sx={{
					position      : 'absolute',
					left          : '50%',
					top           : 0,
					transform     : 'translateX(-50%)',
					zIndex        : 2,
					display       : 'flex',
					alignItems    : 'center',
					justifyContent: 'center',
				}}>
				{timelineDot || (
					<Box
						sx={{
							width       : 16,
							height      : 16,
							borderRadius: '50%',
							bgcolor     : 'primary.main',
						}}
					/>
				)}
			</Box>
			
			{/* Content container */}
			<Box
				sx={{
					display : 'flex',
					width   : '100%',
					position: 'relative',
					// bgcolor : 'red'
				}}>
				{/* Left content */}
				<Box
					sx={{
						width         : '50%',
						boxSizing     : 'border-box',
						paddingRight  : 3,
						display       : 'flex',
						justifyContent: 'flex-end',
						alignItems    : 'start',
						minHeight     : 32,
					}}>
					{left}
				</Box>
				
				{/* Right content */}
				<Box
					sx={{
						width      : '50%',
						boxSizing  : 'border-box',
						paddingLeft: 3,
						display    : 'flex',
						alignItems : 'start',
						minHeight  : 32,
					}}>
					{right}
				</Box>
			</Box>
		</Box>
	);
}

type TimelineProps = {
	children: ReactNode
};

export default function Timeline( { children }: TimelineProps ) {
	// Process children to automatically mark the first item
	const childrenArray = Children.toArray( children );
	const processedChildren = childrenArray.map( ( child, index ) => {
		if ( isValidElement( child ) ) {
			return cloneElement( child, {
				_isFirst: index === 0,
				...child.props,
			} );
		}
		return child;
	} );
	
	return (
		<Box
			sx={{
				position: 'relative',
				width   : '100%',
				// border  : 1,
			}}>
			{/* Vertical line */}
			<Box
				sx={{
					position       : 'absolute',
					left           : '50%',
					top            : 0,
					bottom         : 0,
					width          : 2,
					backgroundColor: ( theme ) => theme.palette.divider,
					transform      : 'translateX(-50%)',
					zIndex         : 1,
				}}
			/>
			
			{/* Timeline items */}
			<Box
				sx={{
					display      : 'flex',
					flexDirection: 'column',
				}}>
				{processedChildren}
			</Box>
		</Box>
	);
}
