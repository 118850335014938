import Form from '@/components/form';
import Loading from '@/components/loading';
import { useGraphQL } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import {
	CommerceRead_ScheduledPaymentsQuery,
	CommerceRead_ScheduledPaymentsQueryVariables,
	CommerceWrite_ScheduledPaymentsMutation,
	CommerceWrite_ScheduledPaymentsMutationVariables,
} from '@/generated/graphql';
import idPick from '@/helpers/idPick';
import getAllScheduledPaymentsWithProperAmounts from '@/modals/scheduledPayments/utils';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { gql } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import { isAfter } from 'date-fns';
import { toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CustomPayments from './customSchedule';
import PaymentSchedulePreview from './preview';
import SplitEvenlyPayments from './splitEvenly';
import TotalSection from './totalSection';

export default function SchedulePaymentsModal( { id }: { id: string } ) {
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const { t } = useTranslation();
	
	const {
		data,
		isLoading,
		isFetching,
	} = useGraphQL<CommerceRead_ScheduledPaymentsQueryVariables, CommerceRead_ScheduledPaymentsQuery>( {
		query: gql`
			query CommerceRead_ScheduledPayments($id: String) {
				commerceRead(id: $id) {
					id
					type
					number
					metadata
					paidTotal
					grandTotal
					dueDate
					scheduledPayments {
						id
						amount
						percent
						reason
						dueDate
						status
						updatedAt
						createdAt
						staff {
							id
						}
					}
					schedulePaymentsData
					staff {
						id
					}
					company {
						id
					}
					client {
						id
					}
				}
			}
		`,
		queryKey : [ 'commerceRead' ],
		variables: { id },
	}, { enabled: Boolean( id ) } );
	
	const commerce = data?.commerceRead;
	
	if ( isLoading || isFetching ) return <Loading/>;
	
	if ( !commerce ) return null;
	
	console.log( 'commerce?.scheduledPayments', commerce?.scheduledPayments );
	
	const validateScheduledPayments = ( values ) => {
		// Validate based on schedule type
		if ( values.schedulePaymentsData.type === 'CUSTOM' ) {
			// For CUSTOM type validation
			
			// Check if there are any payments
			if ( !values.payments || values.payments.length === 0 ) {
				return { valid: false, message: 'You must add at least one payment' };
			}
			
			// Check each payment for required fields
			for ( let i = 0; i < values.payments.length; i++ ) {
				const payment = values.payments[ i ];
				
				// Check for due date
				if ( !payment.dueDate ) {
					return { valid: false, message: `Payment #${i + 1} is missing a due date` };
				}
				
				// Check for amount and percent
				if ( !payment.amount || payment.amount <= 0 ) {
					return { valid: false, message: `Payment #${i + 1} must have an amount greater than 0` };
				}
				
				if ( !payment.percent || payment.percent <= 0 ) {
					return { valid: false, message: `Payment #${i + 1} must have a percentage greater than 0` };
				}
				
				// Check if due dates are in ascending order
				if ( i > 0 ) {
					const prevPaymentDate = new Date( values.payments[ i - 1 ].dueDate );
					const currentPaymentDate = new Date( payment.dueDate );
					
					if ( !isAfter( currentPaymentDate, prevPaymentDate ) ) {
						return {
							valid  : false,
							message: `Payment #${i + 1} due date must be after payment #${i}`,
						};
					}
				}
			}
		} else if ( values.schedulePaymentsData.type === 'SPLIT_EVENLY' ) {
			// For SPLIT_EVENLY type validation
			
			// Check for start date
			if ( !values.schedulePaymentsData.splitEvenlyStartDate ) {
				return { valid: false, message: 'Start date is required' };
			}
			
			// Check for number of payments
			if ( !values.schedulePaymentsData.splitEvenlyNumberOfPayments
				|| values.schedulePaymentsData.splitEvenlyNumberOfPayments <= 0 ) {
				return { valid: false, message: 'Number of payments must be greater than 0' };
			}
			
			// Check for interval
			if ( !values.schedulePaymentsData.splitEvenlyInterval ) {
				return { valid: false, message: 'Payment interval is required' };
			}
		}
		
		return { valid: true };
	};
	
	const scheduledPayments = getAllScheduledPaymentsWithProperAmounts(
		commerce.scheduledPayments,
		commerce.grandTotal,
		commerce.grandTotal - commerce.paidTotal,
	).filter( ( payment ) => payment.status === 'SCHEDULED' );
	
	return (
		<Form
			noClose
			enableReinitialize
			initialValues={{
				payments            : scheduledPayments || [],
				schedulePaymentsData: {
					type                       : commerce?.schedulePaymentsData?.type || 'CUSTOM',
					splitEvenlyNumberOfPayments: commerce?.schedulePaymentsData?.splitEvenlyNumberOfPayments || null,
					splitEvenlyStartDate       : commerce?.schedulePaymentsData?.splitEvenlyStartDate || null,
					splitEvenlyInterval        : commerce?.schedulePaymentsData?.splitEvenlyInterval || 30,
				},
			}}
			onSubmit={async ( values ) => {
				const validation = validateScheduledPayments( values );
				
				if ( !validation.valid ) {
					enqueueSnackbar( validation.message, { variant: 'error' } );
					return;
				}
				
				if ( values.payments?.find( ( payment ) => payment.amount <= 0 || ( payment.percent || 0 ) <= 0 ) ) {
					enqueueSnackbar( t( 'common:no-zero-payment' ), { variant: 'default' } );
					return;
				}
				if ( values.payments?.find( ( payment ) => !payment.dueDate ) ) {
					enqueueSnackbar( t( 'common:payment-date-required' ), { variant: 'default' } );
					return;
				}
				const lastDueDate = values.payments[ values.payments.length - 1 ]?.dueDate;
				
				const allScheduledPayments = [
					...commerce?.scheduledPayments.filter( ( payment ) => payment.status === 'PAID' ) || [],
					...values.payments,
				];
				
				await mutateGraphQL<CommerceWrite_ScheduledPaymentsMutationVariables, CommerceWrite_ScheduledPaymentsMutation>( {
					mutation: gql`
						mutation CommerceWrite_ScheduledPayments($id: String!, $input: OrderValidator!) {
							commerceWrite(id: $id, input: $input) {
								id
							}
						}
					`,
					variables: {
						id   : commerce?.id,
						input: {
							scheduledPayments: [
								...allScheduledPayments.map( ( payment ) => ( {
									...idPick( payment ),
									percent: payment.percent,
									amount : payment.status === 'PAID' ? payment.amount : null,
									reason : payment.reason,
									dueDate: payment.dueDate,
									status : payment.status,
									staff  : commerce?.staff?.id || staff?.id,
									client : commerce?.client?.id || null,
									company: commerce?.company.id,
								} ) ),
							],
							dueDate: commerce?.dueDate ? isAfter( lastDueDate, commerce?.dueDate )
								? lastDueDate
								: commerce?.dueDate : lastDueDate,
							schedulePaymentsData: {
								type                       : values.schedulePaymentsData.type,
								splitEvenlyNumberOfPayments: values.schedulePaymentsData.splitEvenlyNumberOfPayments,
								splitEvenlyStartDate       : values.schedulePaymentsData.splitEvenlyStartDate,
								splitEvenlyInterval        : values.schedulePaymentsData.splitEvenlyInterval,
							},
						},
					},
				} );
				closeModal();
			}}>
			{( formik ) => (
				<ResponsiveModalContainer
					loading={formik.isSubmitting}
					closeOnSave={false}
					title={t( 'common:schedule-payment' )}
					secondaryTitle={`${t( 'common:schedule-payment-des-one' )} ${toLower( commerce?.type )}. ${t( 'common:schedule-payment-des-two' )}`}
					onSave={formik.handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={8}>
							<Stack spacing={1}>
								<CustomPayments commerce={commerce}/>
								<SplitEvenlyPayments commerce={commerce}/>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Stack>
								<TotalSection commerce={commerce}/>
								<PaymentSchedulePreview commerce={commerce}/>
							</Stack>
						</Grid>
					</Grid>
				</ResponsiveModalContainer>
			)}
		</Form>
	);
}
